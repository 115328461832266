define("budgeta/utils/pdf-utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function stripATags(input) {
    if (/<a\s[^>]*>.*?<\/a>/i.test(input)) {
      var parser = new DOMParser();
      var doc = parser.parseFromString(input, "text/html");
      doc.querySelectorAll("a").forEach(function (a) {
        a.replaceWith(a.textContent);
      });
      return doc.body.textContent.trim();
    }
    return input;
  }
  var _default = _exports.default = {
    stripATags: stripATags
  };
});